import React from 'react'

class Footer extends React.Component {
    render() {
        return (
            <footer className="footer">
                (c)2021 Sate-Lite. All Rights Reserved. Website Made by Wuyang Liu
            </footer>
        )
    }
}

export default Footer;