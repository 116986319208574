import React from 'react'
import Header from './components/Header'
import Footer from './components/Footer'
import Main from './components/Main'

function App() {
    return (
        <div className="App">
            <Header/>
            <Footer/>
            <Main />
        </div>
    );
}

export default App;
